import { createPortal } from "react-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { COLORS } from "utils/constants";

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  isDisabled?: boolean;
  color?: string;
}

interface BoxProps {
  $isVisible: boolean;
  $position: { top: number; left: number };
  $color?: string;
}

interface TypeProps {
  color?: string;
}

interface RequirementProps {
  color: string;
  $isMet: boolean;
}

interface BarProps {
  color: string;
}

interface BarValueProps {
  percentage: number;
  color: string;
}

interface IconProps {
  image: string;
}

interface ValuesProps {
  color: string;
}

const TooltipBox = styled.div<BoxProps>`
  position: fixed;
  padding: 15px;
  background-color: #00000099;
  border-top: 5px solid ${(props) => props.$color};
  border-radius: 5px;
  width: 250px;
  font-size: 14px;
  pointer-events: none;
  z-index: 5;
  transition: opacity 200ms ease-out;
  user-select: none;
  backdrop-filter: blur(8px);
  box-shadow: 0 0 4px 0 #000000;

  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  ${(props) =>
    props.$position &&
    `
    top: ${props.$position.top}px;
    left: ${props.$position.left}px;
  `}
`;

export const Trigger = styled.div`
  position: relative;
  height: 100%;
  cursor: pointer;
`;

export const TooltipName = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
`;

export const TooltipType = styled.div<TypeProps>`
  color: ${(props) => props.color || COLORS.TOOLTIP};
  white-space: nowrap;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: -2px;
`;

export const TooltipRequirements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 4px;
`;

export const TooltipRequirement = styled.div<RequirementProps>`
  color: ${(props) => props.color};
  white-space: nowrap;
  text-transform: capitalize;
  font-style: italic;

  opacity: ${(props) => (props.$isMet ? 1 : 0.5)};
`;

export const TooltipBar = styled.div<BarProps>`
  margin-top: 4px;
  transform: skew(-30deg);
  background-color: ${(props) => props.color}50;
  height: 6px;
  width: 100%;
  overflow: hidden;
`;

export const TooltipBarValue = styled.div.attrs(
  ({ percentage }: BarValueProps) => ({
    style: {
      width: `${percentage}%`,
    },
  })
)<BarValueProps>`
  height: 100%;
  background-color: ${(props) => props.color};
  transition: all 200ms ease-out;
`;

export const TooltipDesc = styled.div`
  margin-top: 10px;
  color: #ccc;
`;

export const TooltipInfo = styled.div`
  margin-top: 10px;
  color: #fff;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
`;

export const TooltipProperty = styled(Info)`
  color: ${COLORS.PROPERTIES};
`;

export const TooltipRecharge = styled(Info)`
  color: ${COLORS.ENERGY};
`;

export const TooltipCredits = styled(Info)`
  color: ${COLORS.CREDITS};
  margin-top: 5px;
`;

export const TooltipValues = styled(Info)<ValuesProps>`
  color: ${(props) => props.color};
`;

export const TotalValue = styled.span`
  text-decoration: line-through;
  opacity: 0.75;
`;

export const TooltipRanges = styled(Info)``;

const Icon = styled.div<IconProps>`
  height: 12px;
  width: 12px;
  mask: url(${(props) => props.image}) no-repeat center;
`;

export const PropertyIcon = styled(Icon)`
  background-color: ${COLORS.PROPERTIES};
`;

export const RechargeIcon = styled(Icon)`
  background-color: ${COLORS.ENERGY};
`;

export const CreditsIcon = styled(Icon)`
  background-color: ${COLORS.CREDITS};
`;

export const ValuesIcon = styled(Icon)<ValuesProps>`
  background-color: ${(props) => props.color};
`;

export const RangesIcon = styled(Icon)``;

const useTooltip = (
  triggerRef: React.RefObject<HTMLElement>,
  tooltipRef: React.RefObject<HTMLElement>
) => {
  const [position, setPosition] = useState({ top: -1000, left: -1000 });
  const [isVisible, setIsVisible] = useState(false);

  const updatePosition = useCallback(() => {
    if (!triggerRef.current || !tooltipRef.current) return;

    const trigger = triggerRef.current.getBoundingClientRect();
    const tooltip = tooltipRef.current.getBoundingClientRect();

    // Position below trigger by default, above if not enough space below
    const top =
      window.innerHeight - trigger.bottom < tooltip.height
        ? trigger.top - tooltip.height - 5
        : trigger.bottom + 5;

    // Center horizontally, but keep within viewport
    let left = trigger.left + (trigger.width - tooltip.width) / 2;
    left = Math.max(5, Math.min(left, window.innerWidth - tooltip.width - 5));

    setPosition({ top, left });
  }, [triggerRef, tooltipRef]);

  useEffect(() => {
    if (isVisible) {
      updatePosition();
      window.addEventListener("scroll", updatePosition, true);
      window.addEventListener("resize", updatePosition);
    }

    return () => {
      window.removeEventListener("scroll", updatePosition, true);
      window.removeEventListener("resize", updatePosition);
    };
  }, [isVisible, updatePosition]);

  const showTooltip = useCallback(() => setIsVisible(true), []);
  const hideTooltip = useCallback(() => setIsVisible(false), []);

  return {
    position,
    isVisible,
    showTooltip,
    hideTooltip,
    updatePosition,
  };
};

export const Tooltip = ({
  children,
  content,
  isDisabled = false,
  color = COLORS.TOOLTIP,
}: TooltipProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { position, isVisible, showTooltip, hideTooltip } = useTooltip(
    triggerRef,
    tooltipRef
  );

  return (
    <>
      <Trigger
        ref={triggerRef}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {children}
      </Trigger>
      {!isDisabled &&
        isVisible &&
        createPortal(
          <TooltipBox
            ref={tooltipRef}
            $isVisible={isVisible}
            $position={position}
            $color={color}
          >
            {content}
          </TooltipBox>,
          document.getElementById("tooltipPortal") as HTMLElement
        )}
    </>
  );
};

export default Tooltip;
