import { call, put, select, spawn, takeLeading } from "redux-saga/effects";

import {
  CharacterBaseStats,
  EmploySupplyPayload,
  GetItemPayload,
  InventoryItemModel,
  LoseItemPayload,
  PartTypes,
} from "types";

import { isMaxLevel } from "libs/character";
import {
  getInvalidUpgradeParts,
  getInventoryItemAmount,
  getItemData,
  getPartsToRestore,
  getSupplyData,
  getUpgradeData,
  isSupplyItem,
} from "libs/item";
import { getBaseStatData, getPartData, getSupplyTypeData } from "libs/stats";
import {
  employSupply,
  getSupply,
  getUpgrade,
  installUpgrade,
  removeSupply,
  removeUpgrade,
  restorePart,
  setInstalledUpgrade,
  setUninstalledUpgrade,
  showLinkSectorsOverlay,
  showMessage,
  uninstallUpgrade,
} from "redux/actions";
import { getCharacter, getFight } from "redux/selectors";
import { adjustHealthSaga, gainExpSaga, healSaga } from "./character";
import { uiAnimation } from "./game";

export function* getItemsSaga({
  payload: itemsGained,
}: {
  payload: GetItemPayload[];
}) {
  const {
    data: { inventory },
  } = yield select(getCharacter);

  let exceededItemMaxAmount = false;

  for (const item of itemsGained) {
    // Enforce max amount of each item
    const itemAmount = getInventoryItemAmount(item.slug, inventory);
    const newItemAmount = itemAmount + item.quantity;
    const itemInfo = getItemData(item.slug);
    if (newItemAmount > itemInfo.maxQuantity) {
      yield put(
        showMessage(
          `There is an inventory limit (${itemInfo.maxQuantity}) for ${itemInfo.name}`
        )
      );
      exceededItemMaxAmount = true;
      continue;
    }

    const isSupply = isSupplyItem(item.slug);
    if (isSupply) {
      yield put(getSupply({ slug: item.slug, quantity: item.quantity }));
    } else {
      yield put(getUpgrade({ slug: item.slug, quantity: item.quantity }));
    }
  }

  if (exceededItemMaxAmount) {
    throw new Error();
  }
}

export function* loseItemsSaga({
  payload: itemsGained,
}: {
  payload: LoseItemPayload[];
}) {
  for (const item of itemsGained) {
    const isSupply = isSupplyItem(item.slug);
    if (isSupply) {
      yield put(removeSupply({ slug: item.slug, quantity: item.quantity }));
    } else {
      yield put(removeUpgrade({ slug: item.slug, quantity: item.quantity }));
    }
  }
}

export function* employSupplySaga({
  payload,
}: {
  payload: EmploySupplyPayload;
}) {
  const {
    data: { inventory },
  } = yield select(getCharacter);
  const supplyInfo = getSupplyData(payload.slug);
  if (!supplyInfo) {
    return;
  }
  const supplyTypeInfo = getSupplyTypeData(supplyInfo.type);

  // Make sure the player actually has this item
  const inventorySupply = inventory.supplies.find(
    (item: InventoryItemModel) => item.slug === payload.slug
  );
  if (!inventorySupply) {
    yield put(
      showMessage(`You don't have a ${supplyInfo.name} in your inventory`)
    );
    return;
  }

  try {
    // Show standard message, do before action in case there's an specific overriding supply message
    if (payload.showMessage) {
      yield put(showMessage(`You employed a ${supplyInfo.name}`));
    }

    // Use supply by calling its action and value
    // Only call() allows for handling errors, put() doesn't
    yield call(supplyInfo.action, supplyInfo.payload);

    if (supplyTypeInfo.isConsumable) {
      // If used successfully and the item is consumable, remove item
      yield put(removeSupply({ slug: payload.slug, quantity: 1 }));
    }
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

export function* employRepairKitSaga(healAmount: number) {
  const {
    data: { health, derivedStats },
  } = yield select(getCharacter);
  const currentMaxHealth = derivedStats.current.complete.maxHealth;

  // If health is already full, don't use up repair kit
  if (health >= currentMaxHealth) {
    throw new Error(`Your starship is already fully repaired, chill out`);
  }

  yield call(healSaga, healAmount);
}

export function* employRestoreKitSaga(restoreAmount: number) {
  const {
    data: { weakenedBaseStats },
  } = yield select(getCharacter);
  const { status } = yield select(getFight);

  if (status !== "fighting") {
    throw new Error(`You can only restore your parts while in battle`);
  }

  let restoreAmountRemaining = restoreAmount;

  const partsToRestore = getPartsToRestore(weakenedBaseStats);

  if (partsToRestore.length === 0) {
    throw new Error(
      `Your starship's parts are already fully restored, chill out`
    );
  }

  for (const [key, value] of partsToRestore) {
    const weakenedStat = key as keyof CharacterBaseStats;
    const weakenedAmount = value as number;

    if (restoreAmountRemaining <= 0) {
      break;
    }

    if (restoreAmountRemaining > weakenedAmount) {
      // Save remaining restore amount for next stat
      yield put(
        restorePart({
          baseStatRestored: weakenedStat,
          restorePartAmount: weakenedAmount,
        })
      );
      restoreAmountRemaining -= weakenedAmount;
    } else {
      // Use up rest of restore amount
      yield put(
        restorePart({
          baseStatRestored: weakenedStat,
          restorePartAmount: restoreAmountRemaining,
        })
      );
      restoreAmountRemaining = 0;
    }
  }
}

export function* employExpBoosterSaga(expAmount: number) {
  const {
    data: { level },
  } = yield select(getCharacter);

  if (isMaxLevel(level)) {
    throw new Error(`You're already at max level, chill out`);
  } else {
    yield call(gainExpSaga, expAmount, true);
  }
}

export function* employRecallRiftSaga() {
  // Open overlay for selection

  yield put(showLinkSectorsOverlay());
}

export function* installUpgradeSaga({ payload }: { payload: string }) {
  const {
    data: { inventory, installedUpgrades, totalBaseStats },
  } = yield select(getCharacter);
  const upgrade = getUpgradeData(payload);
  if (!upgrade) {
    return;
  }
  const partInfo = getPartData(upgrade.part);

  // Make sure the player actually has this upgrade
  const inventoryUpgrade = inventory.upgrades.find(
    (upgrade: InventoryItemModel) => upgrade.slug === payload
  );
  if (!inventoryUpgrade) {
    return;
  }

  try {
    const { requirements } = upgrade;
    for (const key in requirements) {
      const baseStat = key as keyof CharacterBaseStats;
      const reqValue = requirements[baseStat] as number;
      const baseStatValue = totalBaseStats[baseStat];
      const baseStatInfo = getBaseStatData(baseStat);

      if (baseStatValue < reqValue) {
        throw new Error(
          `Requires ${baseStatInfo.name} ${reqValue} or higher to install.`
        );
      }
    }

    // Check if the same upgrade is already installed
    const existingUpgrade = installedUpgrades[upgrade.part];
    if (existingUpgrade) {
      if (existingUpgrade === payload) {
        throw new Error(`You already have this upgrade installed`);
      }

      // Uninstall existing upgrade and put back in inventory
      yield put(getUpgrade({ slug: existingUpgrade, quantity: 1 }));
    }

    // Install new upgrade
    yield put(
      setInstalledUpgrade({
        part: upgrade.part,
        slug: payload,
      })
    );

    // If installed successfully, remove upgrade from inventory
    yield put(removeUpgrade({ slug: payload, quantity: 1 }));

    // Show install animation
    yield spawn(uiAnimation, `${upgrade.part}Install`);

    yield put(showMessage(`Installed new ${partInfo.name}: ${upgrade.name}`));
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

export function* uninstallUpgradeSaga({
  payload: part,
}: {
  payload: PartTypes;
}) {
  const {
    data: { installedUpgrades },
  } = yield select(getCharacter);
  const existingUpgrade = installedUpgrades[part];
  if (!existingUpgrade) {
    return;
  }
  const upgrade = getUpgradeData(existingUpgrade);
  const partInfo = getPartData(part);

  try {
    // Uninstall existing upgrade
    yield put(setUninstalledUpgrade(part));

    // Put uninstalled upgrade put back in inventory
    yield put(getUpgrade({ slug: existingUpgrade, quantity: 1 }));

    // Adjust health to new maxHealth, if maxHealth is lower
    yield call(adjustHealthSaga);

    yield put(showMessage(`Uninstalled ${partInfo.name}: ${upgrade.name}`));
  } catch (error: any) {
    yield put(showMessage(error.message));
  }
}

export function* adjustUpgradesSaga(): any {
  const {
    data: { installedUpgrades, totalBaseStats },
  } = yield select(getCharacter);

  const invalidParts = getInvalidUpgradeParts(
    installedUpgrades,
    totalBaseStats
  );

  for (const partKey of invalidParts) {
    const part = partKey as PartTypes;

    yield call(uninstallUpgradeSaga, { payload: part });
  }

  if (invalidParts.length > 0) {
    yield put(
      showMessage(
        `Certain upgrades were uninstalled due to insufficient stats after reset`
      )
    );
  }
}

export default function* itemSagas() {
  yield takeLeading(employSupply, employSupplySaga);
  yield takeLeading(installUpgrade, installUpgradeSaga);
  yield takeLeading(uninstallUpgrade, uninstallUpgradeSaga);
}
