import { useDispatch, useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components";

import uninstallImage from "assets/uninstall.svg";
import ItemIcon from "components/base/ItemIcon";
import {
  floatAnimation,
  upgradesScreenAnimation,
} from "components/styles/animations";
import AvatarShip from "components/ui/AvatarShip";
import { hasAnimation } from "libs/stats";
import { showMessage, uninstallUpgrade } from "redux/actions";
import { getCharacter } from "redux/selectors";
import { PartTypes } from "types";
import { useImmer } from "use-immer";
import { COLORS } from "utils/constants";

interface UpgradeProps {
  canInstall: boolean;
}

interface UpgradesScreenState {
  highlightPart: PartTypes | null;
}

const Upgrades = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px #43fffa solid;
  border-radius: 5px;
  background-color: #00000099;
  background-size: 2em 2em;
  background-position: center center;
  background-image: linear-gradient(to right, #aaaaaa30 1px, transparent 1px),
    linear-gradient(to bottom, #aaaaaa30 1px, transparent 1px);
  animation: ${upgradesScreenAnimation} 1s linear infinite;
`;

const ShipWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Ship = styled.div`
  height: 60%;
  animation: ${floatAnimation} 3.5s ease-in-out infinite;
`;

const ComponentsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;

const UninstallWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  opacity: 0;
  transition: opacity 200ms ease-out;
  pointer-events: none;
`;

const UninstallButton = styled.div`
  height: 100%;
  background-color: white;
  mask: url(${uninstallImage}) no-repeat center;
  mask-size: 60%;
`;

const installAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 #ffffff99;
  }

  80% {
    transform: scale(1.3);
    box-shadow: 0 0 10px #ffffff99;
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 #ffffff00;
  }
`;

const installMixin = css`
  animation: ${installAnimation} 500ms ease-out forwards;
`;

const Component = styled.div<{ animations: string[] }>`
  background-color: black;
  border: 1px white solid;
  width: 10%;
  aspect-ratio: 1/1;
  position: absolute;
  cursor: pointer;
  border-radius: 4px;

  &:hover ${UninstallWrapper} {
    opacity: 1;
  }
`;

const ComponentWeapons = styled(Component)`
  top: 30%;
  right: 10%;
  border-color: ${COLORS.FIREPOWER};
  ${(props) => hasAnimation(props.animations, "weaponsInstall") && installMixin}
`;

const ComponentShields = styled(Component)`
  top: 30%;
  left: 10%;
  border-color: ${COLORS.RESILIENCE};
  ${(props) => hasAnimation(props.animations, "shieldsInstall") && installMixin}
`;

const ComponentThrusters = styled(Component)`
  top: 45.5%;
  left: 10%;
  border-color: ${COLORS.SPEED};
  ${(props) =>
    hasAnimation(props.animations, "thrustersInstall") && installMixin}
`;

const ComponentTargetingSystem = styled(Component)`
  bottom: 30%;
  right: 10%;
  border-color: ${COLORS.PRECISION};
  ${(props) =>
    hasAnimation(props.animations, "targetingSystemInstall") && installMixin}
`;

const ComponentReactorCore = styled(Component)`
  bottom: 30%;
  left: 10%;
  border-color: ${COLORS.ENERGY};
  ${(props) =>
    hasAnimation(props.animations, "reactorCoreInstall") && installMixin}
`;

const PART_COMPONENTS = {
  weapons: ComponentWeapons,
  shields: ComponentShields,
  thrusters: ComponentThrusters,
  targetingSystem: ComponentTargetingSystem,
  reactorCore: ComponentReactorCore,
};

export default function InstalledUpgrades({
  canInstall = false,
}: UpgradeProps) {
  const dispatch = useDispatch();
  const character = useSelector(getCharacter);
  const {
    data: {
      shipUpgrades,
      ui: { animations },
    },
  } = character;

  const initialUpgradesScreenState: UpgradesScreenState = {
    highlightPart: null,
  };
  const [upgradesScreenState, setUpgradesScreenState] =
    useImmer<UpgradesScreenState>(initialUpgradesScreenState);

  const setHighlightPart = (part: PartTypes) => {
    setUpgradesScreenState((draft) => {
      draft.highlightPart = part;
    });
  };

  const resetHighlightPart = () => {
    setUpgradesScreenState((draft) => {
      draft.highlightPart = null;
    });
  };

  const handleUninstallUpgrade = (part: PartTypes) => {
    if (!!canInstall) {
      dispatch(uninstallUpgrade(part));
    } else {
      dispatch(
        showMessage(`Find the nearest Shipworks to uninstall this upgrade`)
      );
    }
  };

  return (
    <Upgrades>
      <ShipWrapper>
        <Ship>
          <AvatarShip
            isPreview
            highlightPart={upgradesScreenState.highlightPart}
            shipData={character.data}
          />
        </Ship>
      </ShipWrapper>
      <ComponentsWrapper>
        {Object.entries(PART_COMPONENTS).map(([key, value]) => {
          const partType = key as PartTypes;
          const PartComponent = value;
          return (
            <PartComponent
              key={partType}
              animations={animations}
              onMouseEnter={() => setHighlightPart(partType)}
              onMouseLeave={resetHighlightPart}
              onClick={() => handleUninstallUpgrade(partType)}
            >
              <ItemIcon slug={shipUpgrades[partType].slug} />
              {!!canInstall && !shipUpgrades[partType].isDefault && (
                <UninstallWrapper>
                  <UninstallButton />
                </UninstallWrapper>
              )}
            </PartComponent>
          );
        })}
      </ComponentsWrapper>
    </Upgrades>
  );
}
