import creditsImage from "assets/credits.svg";
import Tooltip, {
  CreditsIcon,
  PropertyIcon,
  TooltipBar,
  TooltipBarValue,
  TooltipCredits,
  TooltipDesc,
  TooltipInfo,
  TooltipName,
  TooltipProperty,
  TooltipRequirement,
  TooltipRequirements,
  TooltipType,
  TooltipValues,
  ValuesIcon,
} from "components/base/Tooltip";
import {
  getComparisonIndicator,
  getSupplyData,
  getUpgradeComparisonStats,
  getUpgradeData,
  isSupplyItem,
} from "libs/item";
import {
  getBaseStatData,
  getDerivedStatData,
  getPartData,
  getPropertyData,
  getSupplyTypeData,
} from "libs/stats";
import { useSelector } from "react-redux";
import { getCharacter } from "redux/selectors";
import styled from "styled-components";
import {
  CharacterBaseStats,
  CharacterDerivedStats,
  UpgradeComparisonModel,
} from "types";
import { COLORS } from "utils/constants";

interface ItemProps {
  slug: string;
}

interface UpgradeImageWrapperProps {
  color: string;
}

const UpgradeImageWrapper = styled.div<UpgradeImageWrapperProps>`
  width: calc(100% - 20%);
  padding: 10%;
  border-radius: 4px;
  overflow: hidden;
  background: linear-gradient(#000000, 60%, ${(props) => props.color}85);
  background-blend-mode: darken;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
`;

const ItemImage = styled.img`
  width: 100%;
  display: block;
  border-radius: 4px;
  overflow: hidden;
  user-select: none;
`;

const UpgradeImage = styled(ItemImage)`
  filter: brightness(1.1) contrast(1.2) saturate(1.2)
    drop-shadow(0 0 3px #ffffff);
`;

const ItemIcon = ({ slug }: ItemProps) => {
  const {
    data: { totalBaseStats, installedUpgrades },
  } = useSelector(getCharacter);

  const isSupply = !!isSupplyItem(slug);

  const supply = isSupply ? getSupplyData(slug) : null;
  const upgrade = !isSupply ? getUpgradeData(slug) : null;

  if (!!supply) {
    const supplyTypeInfo = getSupplyTypeData(supply.type);
    const propertyInfo = supply.property && getPropertyData(supply.property);
    return (
      <Tooltip
        color={COLORS.SUPPLY}
        content={
          <>
            <TooltipName>{supply.name}</TooltipName>
            <TooltipType color={COLORS.SUPPLY}>{`${
              supplyTypeInfo.isConsumable ? `Consumable` : `Non-consumable`
            } Supply`}</TooltipType>
            <TooltipDesc>{supply.description}</TooltipDesc>
            <TooltipInfo>
              {!!propertyInfo && supply.payload && (
                <TooltipProperty>
                  <PropertyIcon image={propertyInfo.image} />
                  {propertyInfo.name}:{" "}
                  {propertyInfo.addFormatter(supply.payload)}
                </TooltipProperty>
              )}
              <TooltipCredits>
                <CreditsIcon image={creditsImage} />
                Credits: {supply.credits}
              </TooltipCredits>
            </TooltipInfo>
          </>
        }
      >
        <ItemImage src={supply.image} />
      </Tooltip>
    );
  }

  if (!!upgrade) {
    const { gradeData, requirements } = upgrade;
    const partData = getPartData(upgrade.part);
    const installedUpgrade = installedUpgrades[upgrade.part];
    const installedStats = !!installedUpgrade
      ? getUpgradeData(installedUpgrade).derivedStats
      : {};
    const comparisonStats = getUpgradeComparisonStats(
      upgrade.derivedStats,
      installedStats
    );

    return (
      <Tooltip
        color={gradeData.color}
        content={
          <>
            <TooltipName color={gradeData.color}>{upgrade.name}</TooltipName>
            {!upgrade.isDefault && (
              <TooltipType color={gradeData.color}>
                {`${gradeData.name} ${partData.name}`}
              </TooltipType>
            )}
            <TooltipRequirements>
              {Object.entries(requirements).map(([key, value]) => {
                const baseStat = key as keyof CharacterBaseStats;
                const reqValue = value as number;
                const baseStatInfo = getBaseStatData(baseStat);
                const percentage = (totalBaseStats[baseStat] / reqValue) * 100;
                const isMet = percentage >= 100;

                return (
                  <TooltipRequirement
                    key={baseStat}
                    color={baseStatInfo.color}
                    $isMet={isMet}
                  >
                    {`${baseStatInfo.name} required: ${reqValue}`}
                    <TooltipBar color={baseStatInfo.color}>
                      <TooltipBarValue
                        percentage={percentage}
                        color={baseStatInfo.color}
                      />
                    </TooltipBar>
                  </TooltipRequirement>
                );
              })}
            </TooltipRequirements>
            <TooltipDesc>{upgrade.description}</TooltipDesc>
            <TooltipInfo>
              {Object.entries(comparisonStats).map(([key, value]) => {
                const statSlug = key as keyof CharacterDerivedStats;
                const statComparison = value as UpgradeComparisonModel;
                const statInfo = getDerivedStatData(statSlug);
                return (
                  <TooltipValues key={statSlug} color={statInfo.color}>
                    <ValuesIcon image={statInfo.image} color={statInfo.color} />
                    {statInfo.name}:{" "}
                    {statInfo.addFormatter(statComparison.value)}{" "}
                    {getComparisonIndicator(statComparison.difference)}
                  </TooltipValues>
                );
              })}
              {!upgrade.isDefault && (
                <TooltipCredits>
                  <CreditsIcon image={creditsImage} />
                  {`Credits: ${upgrade.credits}`}
                </TooltipCredits>
              )}
            </TooltipInfo>
          </>
        }
      >
        <UpgradeImageWrapper color={gradeData.color}>
          <UpgradeImage src={upgrade.image} />
        </UpgradeImageWrapper>
      </Tooltip>
    );
  }

  return <></>;
};

export default ItemIcon;
